<template>
  <v-container
    fluid
    tag="section"
    class="mt-1"
  >
    <v-card>
      <v-card-title class="card-title">
        <h4>{{ $t('users') }}</h4>
        <v-spacer />
        <v-btn
          color="primary"
          rounded
          small
          dark
          @click="addClick"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <alert
        v-if="alertEnabled"
        :alert-msg="alertMsg"
        :alert-type="alertType"
      />
      <v-data-table
        :loading="loading"
        :headers="mainHeaders"
        :items="filteredUsers"
        :items-per-page="filteredUsers.length"
        :footer-props="footerProps"
        :search="search"
        :single-expand="true"
        :expanded.sync="expanded"
        dense
        sort-by="Id"
        item-key="Id"
        fluid
        fixed-header
        style="cursor:pointer"
        class="elevation-1"
        @click:row="expand"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-spacer />
            <v-spacer />
            <v-spacer />
            <v-spacer />
            <v-row>
              <v-col
                cols="12"
                lg="3"
                md="3"
                sm="3"
                offset="8"
              >
                <v-select
                  v-if="!loading"
                  v-model="selectedStatus"
                  :items="userStatuses"
                  :loading="loading"
                  item-text="ShortDescription"
                  item-value="LowValue"
                  dense
                  placeholder="All"
                  multiple
                  class="mt-6"
                />
              </v-col>
            </v-row>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
            />
            <manage-user
              v-if="dialog"
              :dialog="dialog"
              @user-dialog-closed="dialogClosed"
              @successfully-added-user="addedUser"
            />
            <v-dialog
              v-model="dialogDelete"
              max-width="500px"
            >
              <v-card>
                <v-card-title class="text-h5">
                  {{ $t('areYouSureDelete') }}
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDelete"
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteUserConfirm"
                  >
                    {{ $t('ok') }}
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                v-bind="attrs"
                @click="deleteUser(item)"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('deleteUser') }}</span>
          </v-tooltip>
        </template>
        <template v-slot:expanded-item="{ headers }">
          <td :colspan="headers.length">
            <details-card
              :type="'users'"
              :details="detailedUser"
              @refresh-users="getUsers"
            />
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import ManageUser from '../Dialogs/ManageUser.vue'
  import DetailsCard from '../Cards/DetailsCard.vue'
  import Alert from '../component/Alert.vue'
  export default {
    components: {
      ManageUser,
      DetailsCard,
      Alert,
    },
    data: () => ({
      alertEnabled: false,
      alertMsg: '',
      alertType: '',
      search: '',
      userWasManaged: false,
      loading: true,
      dialog: false,
      dialogEdit: false,
      dialogDelete: false,
      userToBeDelete: -1,
      selectedStatus: [],
      footerProps: { 'items-per-page-options': [-1, 5, 10, 15] },
      users: [],
      selectedUser: {},
      expanded: [],
    }),
    computed: {
      ...mapState({
        usersFromRepo: (state) => state.user.users,
        detailedUser: (state) => state.user.selectedUser,
        customers: (state) => state.customer.customers,
        userStatuses: (entytiStatuses) => entytiStatuses.verifier.userStatuses,
      }),
      ...mapGetters({
        getVerifierCustomerId: 'verifier/getVerifierCustomerId',
        getVerifierId: 'verifier/getVerifierId',
        getVerifierLongDescription: 'verifier/getVerifierLongDescription',
        getVerifierLowValue: 'verifier/getVerifierLowValue',
        getVerifierRvDomain: 'verifier/getVerifierRvDomain',
        getVerifierShortDescription: 'verifier/getVerifierShortDescription',
        getObjectVerifierById: 'verifier/getObjectVerifierById',
      }),
      mainHeaders () {
        let result = []
        result = [
          { text: this.$t('Id'), value: 'Id', align: 'start' },
          { text: this.$t('name'), value: 'Fullname' },
          { text: this.$t('email'), value: 'Email' },
          { text: this.$t('phone-number'), value: 'Phone' },
          { text: this.$t('status'), value: 'StatusForDisplay' },
          { text: this.$t('actions'), value: 'actions', sortable: false },
        ]
        return result
      },
      filteredUsers () {
        let users = []
        this.users.forEach(() => {
          if (this.selectedStatus.length === 0) {
            users = this.users
          } else {
            users = this.users.filter(user => this.selectedStatus.includes(user.Status))
          }
        })
        return users
      },
    },
    async mounted () {
      await this.getUsers()
    },
    methods: {
      addClick () {
        this.$nextTick(() => {
          this.dialog = true
        })
      },
      async getUsers () {
        this.loading = true
        await this.$store.dispatch('user/getUser')
          .then(() => {
            this.users = this.usersFromRepo.map(c => {
              return {
                Id: c.Id,
                Fullname: c.FullName,
                Email: c.Email,
                Phone: c.Phone,
                StatusForDisplay: this.getVerifierShortDescription(this.userStatuses, c.Status),
                Status: c.Status,
              }
            })
            this.loading = false
          })
      },
      editUser (user) {
        Object.assign(this.selectedUser, user)
        this.dialogEdit = true
      },

      deleteUser (user) {
        this.userToBeDelete = user.Id
        this.dialogDelete = true
      },
      dialogClosed () {
        this.dialogEdit = false
        this.dialog = false
        this.getUsers()
      },
      closeDelete () {
        this.userToBeDelete = -1
        this.dialogDelete = false
      },
      deleteUserConfirm () {
        this.$store.dispatch('user/deleteUser', this.userToBeDelete)
          .then(res => {
            if (res.resFlag) {
              this.dialogDelete = false
              this.getUsers()
            }
          })
      },
      async expand (item) {
        await this.$store.dispatch('user/getUserById', item.Id)
        if (item === this.expanded[0]) this.expanded = []
        else this.expanded = [item]
      },
      addedUser (msg) {
        this.alertEnabled = true
        this.alertMsg = msg
        this.alertType = 'success'
      },
    },
  }
</script>

<style lang="scss">
</style>
